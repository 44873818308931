<template>
	<div>
		<AsiBtn :icon="icons.frameAssembly" color="secondary lighten-2 mb-2" x-small @click="updateDialog.open()" >
			{{ $t('frameAssembly.terms.edit') }}
		</AsiBtn>
		<FrameAssemblyUpdateDialog :position="position" :cart-id="cartId" :open="updateDialog.isOpen" @cancel="updateDialog.close()" @save="save(); updateDialog.close();" />
	</div>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from "vue-property-decorator";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import FrameAssemblyUpdateDialog from "@/components/frame-assembly/FrameAssemblyUpdateDialog.vue";
	import DialogHandler from "@/components/common/DialogHandler";
	import Icon from "@/plugins/icons";
	import {ICartPositionShopSimple} from "@/models/cart-position/CartPositionShopModels";

	@Component({
		components: {FrameAssemblyUpdateDialog, AsiBtn}
	})
	export default class FrameAssemblyButton extends Vue {

		@Prop({type: Object, required: true})
		public position!:  ICartPositionShopSimple;

		@Prop({type: String, required: true})
		public cartId!: string;

		private icons = Icon;

		private updateDialog: DialogHandler = new DialogHandler();

		@Emit('save')
		public save(): void {
			return;
		}
	}
</script>

<style lang="scss" scoped>

</style>
