<template>
	<div class="frame-assembly-wrapper" :class="{'is-mobile': sMobile}">
		<div :class="['frame d-flex flex-column align-center', !sMobile ? 'pa-5' : 'pt-5 px-3']">
			<div class="d-flex flex-column">
				<span class="font-weight-bold pb-3">{{ $t('frameAssembly.frameType') }}</span>
				<ItemCardFrameAssemblyTypeSelection :frame-type="model.frameType" :small="sMobile" @changed="model.frameType = $event"/>
			</div>
			<ItemCardFrameAssemblyFrame :type="model.frameType" :width="+model.width" :height="+model.height"/>
		</div>
		<v-divider class="divider" vertical/>
		<div :class="['form', !sMobile ? 'pa-5' : 'pt-5']">
			<v-container>
				<v-row>
					<v-col :cols="sMobile ? 12 : 6">
						<span class="font-weight-bold pb-3">{{ $t('frameAssembly.width') }}</span>
						<AsiTextFieldSimple v-model="model.width" type="number" class="mt-3"/>
					</v-col>
					<v-col :cols="sMobile ? 12 : 6">
						<span class="font-weight-bold pb-3">{{ $t('frameAssembly.height') }}</span>
						<AsiTextFieldSimple v-model="model.height" type="number" class="mt-3"/>
					</v-col>
				</v-row>
				<v-row>
					<v-col :cols="sMobile ? 12 : 6">
										<span class="font-weight-bold pb-3">{{
												$t('frameAssembly.installation')
											}}</span>
						<AsiRadioGroup v-model="model.installation">
							<v-radio :label="$t('constants.frame')" :value="frameAssemblyInstallation.frame"/>
							<v-radio :label="$t('constants.wing')" :value="frameAssemblyInstallation.wing"/>
						</AsiRadioGroup>
					</v-col>
					<v-col :cols="sMobile ? 12 : 6">
										<span class="font-weight-bold pb-3">{{
												$t('frameAssembly.measuringPoint')
											}}</span>
						<AsiRadioGroup v-model="model.measuringPoint">
							<v-radio :label="$t('constants.rebate')" :value="frameAssemblyMeasuringPoint.rebate"/>
							<v-radio :label="$t('constants.light')" :value="frameAssemblyMeasuringPoint.light"/>
						</AsiRadioGroup>
					</v-col>
				</v-row>

				<v-row>
					<v-col>
						<span class="font-weight-bold pb-3">{{ $t('frameAssembly.description') }}</span>
						<AsiTextareaSimple v-model="model.description" class="mt-3"/>
					</v-col>
				</v-row>
			</v-container>
		</div>
	</div>
</template>

<script lang="ts">
	import {Component, Emit, Prop, Watch} from "vue-property-decorator";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import ItemCardFrameAssemblyTypeSelection from "@/components/item/shop/ItemCardFrameAssemblyTypeSelection.vue";
	import ItemCardFrameAssemblyFrame from "@/components/item/shop/ItemCardFrameAssemblyFrame.vue";
	import AsiRadioGroup from "@/components/common/AsiRadioGroup";
	import AsiTextareaSimple from "@/components/common/AsiTextareaSimple";
	import AsiTextFieldSimple from "@/components/common/AsiTextFieldSimple";
	import ItemPriceTable from "@/components/item/ItemPriceTable.vue";
	import ItemQuantitySelection from "@/components/item/shop/ItemQuantitySelection.vue";
	import {mixins} from "vue-class-component";
	import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
	import Icon from "@/plugins/icons";
	import FrameAssemblyCreate from "@/models/frame-assembly/FrameAssemblyCreate";
	import FrameAssemblyUpdate from "@/models/frame-assembly/FrameAssemblyUpdate";
	import {IFrameAssembly} from "@/models/frame-assembly/FrameAssemblyModels";
	import {FrameAssemblyInstallation, FrameAssemblyMeasuringPoint} from "@/helpers/constants";

	@Component({
		components: {
			ItemQuantitySelection,
			ItemPriceTable,
			AsiTextFieldSimple,
			AsiTextareaSimple,
			AsiRadioGroup,
			ItemCardFrameAssemblyFrame,
			ItemCardFrameAssemblyTypeSelection,
			AsiBtn
		}
	})
	export default class FrameAssemblyConfigurator extends mixins(ResponsiveChecks) {
		private icons = Icon;

		@Prop({type: Object, default: null})
		public frameAssembly!: IFrameAssembly | null;

		private model: FrameAssemblyCreate = new FrameAssemblyCreate();

		private frameAssemblyInstallation = FrameAssemblyInstallation;
		private frameAssemblyMeasuringPoint = FrameAssemblyMeasuringPoint;

		public created(): void {
			if (this.frameAssembly !== null) {
				this.model.frameType = this.frameAssembly.frameType;
				this.model.width = this.frameAssembly.width;
				this.model.height = this.frameAssembly.height;
				this.model.measuringPoint = this.frameAssembly.measuringPoint;
				this.model.installation = this.frameAssembly.installation;
				this.model.description = this.frameAssembly.description;
			}
		}

		@Watch('model', {deep: true, immediate: true})
		private onModelChanged(): void {
			if (this.frameAssembly === null) {
				this.assemblyCreated();
			} else {
				this.changed();
			}
		}

		@Emit('created')
		public assemblyCreated(): FrameAssemblyCreate {
		    return this.model;
		}

		@Emit('changed')
		public changed(): FrameAssemblyUpdate {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			const updateModel = new FrameAssemblyUpdate(this.frameAssembly!);
			updateModel.frameType = this.model.frameType;
			updateModel.width = this.model.width;
			updateModel.height = this.model.height;
			updateModel.measuringPoint = this.model.measuringPoint;
			updateModel.installation = this.model.installation;
			updateModel.description = this.model.description;

			return updateModel;
		}
	}
</script>

<style lang="scss" scoped>
	.frame-assembly-wrapper {
		width: 100%;
		display: grid;

		&:not(.is-mobile) {
			grid-template-areas:    'frame divider form';
			grid-template-columns: 1fr auto 1fr;
		}

		&.is-mobile {
			grid-template-areas:    'frame'
								'divider'
								'form';
			grid-template-columns: 100%;
		}

		.frame {
			grid-area: frame;
			max-width: 100%;

			> div {
				width: 100%;
			}
		}

		.divider {
			grid-area: divider;
		}

		.form {
			grid-area: form;
			max-width: 100%;
		}
	}
</style>
