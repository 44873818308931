<template>
	<div class="d-flex flex-column overflow-y-auto">
		<div class="flex-grow-1 flex-shrink-1 overflow-y-auto cart-items">
			<v-list v-if="cart !== null && cart.positions.length > 0" class="py-0">
				<template v-for="(pos, i) in cart.positions">
					<v-divider v-if="i !== 0" :key="`${pos.id}-divider`"/>
					<v-list-item :key="`${pos.id}-entry`" class="px-3" :class="{
						'error': pos.price === null,
						'lighten-3': pos.price === null,
					}">
						<v-list-item-avatar class="mr-2">
							<AsiAvatar :icon="icons.item" :size="32"/>
						</v-list-item-avatar>
						<v-list-item-content>
							<v-list-item-title class="mb-2 item-pos" @click="showItem(pos)">
								{{ itemTitle(pos.item) }}
							</v-list-item-title>
							<v-list-item-subtitle v-if="pos.frameAssembly !== null && pos.frameAssembly !== undefined">
								<p class="mb-1">{{ $t('frameAssembly.mass', {width: pos.frameAssembly.width, height: pos.frameAssembly.height}) }}</p>
								<FrameAssemblyUpdateButton :position="pos" :cart-id="id" @save="reloadCart"/>
							</v-list-item-subtitle>
							<v-list-item-subtitle class="d-flex flex-row justify-space-between align-center">
								<div class="d-flex flex-row align-center">
									<ItemQuantitySelection :value="pos.quantity" :unit="itemSalesUnitOfMeasure(pos)"
									                       x-small class="mr-3" @input="updateQuantity(pos, $event)"
									                       debounce/>
									<AsiBtn :icon="icons.delete" x-small @click.stop="removePosition(pos)"/>
								</div>
								<span v-if="pos.price !== null">
									{{
										$n((finalAmount(pos.price) * pos.quantity), {
											key: 'currencyDisplay',
											currency: pos.price.currency.currencyCode
										})
									}}
								</span>
								<span v-else> {{ $t('cart.terms.itemUnavailable') }} </span>
							</v-list-item-subtitle>
							<v-list-item-subtitle v-if="showCustomerReferences">
								<CartPositionCustomerReferenceInput :position="pos" class="mt-2"/>
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</template>

				<template v-if="cart.promotionalItem !== null">
					<v-divider/>
					<v-list-item class="px-3">
						<v-list-item-avatar class="mr-2">
							<AsiAvatar :icon="icons.favorite" bg-color="success" icon-color="white" :size="32"/>
						</v-list-item-avatar>
						<v-list-item-content>
							<v-list-item-subtitle class="d-flex flex-row justify-space-between align-center">
								<span class="body-1 black--text">
									{{ itemTitle(cart.promotionalItem.item) }}
								</span>
								<span>
									{{ $t('item.promotionalItem.terms.free') }}
								</span>
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</template>

				<template v-if="trimmingCost.qty > 0">
					<v-divider/>
					<v-list-item class="px-3">
						<v-list-item-content>
							<v-list-item-subtitle class="d-flex flex-row justify-space-between align-center">
								<span>{{ $t('cart.terms.trimmingCost') }} {{ trimmingCost.qty }}x</span>
								<span>
									{{
										$n(trimmingCost.totalAmount, {
											key: 'currencyDisplay',
											currency: trimmingCost.currencyCode
										})
									}}
								</span>
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</template>

				<template v-if="cart.minimumOrderSurcharge !== null">
					<v-divider/>
					<v-list-item class="px-3">
						<v-list-item-content>
							<v-list-item-subtitle class="d-flex flex-row justify-space-between align-center">
								<span>{{ $t('cart.minimumOrderSurcharge') }}</span>
								<span>
									{{
										$n(finalAmount(cart.minimumOrderSurcharge.price), {
											key: 'currencyDisplay',
											currency: cart.minimumOrderSurcharge.price.currency.currencyCode
										})
									}}
								</span>
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</template>

				<template v-if="cart.bulkyGoodsSurcharge !== null && showShipment  && cart.shipmentType !== null">
					<v-divider/>
					<v-list-item class="px-3">
						<v-list-item-content>
							<v-list-item-subtitle class="d-flex flex-row justify-space-between align-center">
								<span>{{ $t('cart.bulkyGoodsSurcharge') }}</span>
								<span>
									{{
										$n(finalAmount(cart.bulkyGoodsSurcharge.price), {
											key: 'currencyDisplay',
											currency: cart.bulkyGoodsSurcharge.price.currency.currencyCode
										})
									}}
								</span>
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</template>

				<template v-if="showShipment && cart.shipmentType !== null">
					<v-divider/>
					<v-list-item class="px-3">
						<v-list-item-content>
							<v-list-item-subtitle class="d-flex flex-row justify-space-between align-center">
								<span class="mt-n1">
									{{ $t('shipmentType.postage') }} ({{ shipmentTypeLabel }})
								</span>
								<span>
									{{
										$n(finalAmount(cart.shipmentType.postage), {
											key: 'currencyDisplay',
											currency: cart.shipmentType.postage.currency.currencyCode
										})
									}}
								</span>
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</template>
			</v-list>
			<div v-else class="flex-grow-1 flex-shrink-1 pa-6 text-center grey--text">
				{{ $t('cart.terms.noActiveOrEmptyCart') }}
			</div>
		</div>

		<CartTotalEntries :cart="cart" bg-color="grey lighten-4"
		                  :include-shipment="showShipment"
		                  :include-vat="amountsInclVat"/>

		<div v-if="!hideCheckoutButton" class="flex-grow-0 flex-shrink-0">
			<v-divider class="grey lighten-1"/>
			<div class=" pa-3 grey lighten-2">
				<AsiBtn :disabled="!canOrder" @click="showCheckout" block color="primary">
					{{ $t('cart.terms.orderNow') }}
				</AsiBtn>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import {Component, Prop} from "vue-property-decorator";
	import {mixins} from "vue-class-component";
	import RightChecks from "@/mixins/RightChecks.vue";
	import AsiConfirmDialog from "@/components/common/AsiConfirmDialog.vue";
	import ItemQuantitySelection from "@/components/item/shop/ItemQuantitySelection.vue";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import AsiAvatar from "@/components/common/AsiAvatar.vue";
	import Icon from "@/plugins/icons";
	import {ICartShopListEntry} from "@/models/cart/CartShopModels";
	import CartHelper from "@/helpers/CartHelper";
	import {ICartPositionShopSimple} from "@/models/cart-position/CartPositionShopModels";
	import Snackbar from "@/helpers/Snackbar";
	import {IItemShopSimple} from "@/models/item/ItemShopModels";
	import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
	import VueI18n from "vue-i18n";
	import {Routes, UnitOfMeasure} from "@/helpers/constants";
	import AsiTextFieldSimple from "@/components/common/AsiTextFieldSimple";
	import CartPositionCustomerReferenceInput from "@/components/cart-position/CartPositionCustomerReferenceInput.vue";
	import CartTotalEntries from "@/components/cart/shop/CartTotalEntries.vue";
	import {IPrice} from "@/models/price/PriceModels";
	import TotalHelper from "@/helpers/TotalHelper";
	import FrameAssemblyUpdateDialog from "@/components/frame-assembly/FrameAssemblyUpdateDialog.vue";
	import UserUpdateDialogName from "@/components/user/UserUpdateDialogName.vue";
	import ItemHelper from "@/models/item/ItemHelper";
	import FrameAssemblyUpdateButton from "@/components/frame-assembly/FrameAssemblyUpdateButton.vue";
	import TranslateResult = VueI18n.TranslateResult;

	@Component({
		components: {
			FrameAssemblyUpdateButton,
			UserUpdateDialogName,
			FrameAssemblyUpdateDialog,
			CartTotalEntries,
			CartPositionCustomerReferenceInput,
			AsiTextFieldSimple, AsiAvatar, AsiBtn, ItemQuantitySelection, AsiConfirmDialog
		}
	})
	export default class CartContent extends mixins(RightChecks) {

		@Prop({required: true})
		public id!: string | null;

		@Prop({type: Boolean, default: false})
		public hideCheckoutButton!: boolean;

		@Prop({type: Boolean, default: false})
		public showShipment!: boolean;

		@Prop({type: Boolean, default: false})
		public showCustomerReferences!: boolean;

		private icons = Icon;

		private get cart(): ICartShopListEntry | null {
			return this.id === null ? null : this.$store.getters['cart/cartById'](this.id);
		}

		private get amountsInclVat(): boolean {
			return this.$store.state.ui.amountsInclVat;
		}

		private set amountsInclVat(value: boolean) {
			this.$store.commit('ui/setAmountsInclVat', value);
		}

		private get shipmentTypeLabel(): string | TranslateResult | null {
			if (this.cart === null || this.cart.shipmentType === null) return null;
			return TranslatedValueHelper.get(this.cart.shipmentType.name, this.$i18n.locale, true);
		}

		private get canOrder(): boolean {
			return this.cart !== null
				&& this.cart.positions.length > 0
				&& !this.cart.positions.some(pos => pos.price === null);
		}

		private get trimmingCost(): { qty: number, totalAmount: number, currencyCode: string | null } {
			if (this.cart === null || this.cart.positions.length < 1) {
				return {qty: 0, totalAmount: 0, currencyCode: null};
			}

			const trimmingCostPrices = (this.cart.positions
				.map(pos => pos.trimmingCost)
				.filter(tc => tc !== null) as IPrice[]);

			if (trimmingCostPrices.length < 1) {
				return {qty: 0, totalAmount: 0, currencyCode: null};
			}

			const currency = trimmingCostPrices[0].currency;
			const trimmingCostAmounts = trimmingCostPrices.map(tc => this.finalAmount(tc));

			const qty = trimmingCostAmounts.length;
			const amount = trimmingCostAmounts.reduce((acc: number, cur: number) => acc + cur, 0);

			return {qty: qty, totalAmount: amount, currencyCode: currency.currencyCode};
		}

		private updateQuantity(position: ICartPositionShopSimple, quantity: number): void {
			this.$store.dispatch('cart/updatePositionQuantity', {
				positionId: position.id,
				quantity: quantity,
			}).catch(() => Snackbar.updateError());
		}

		private itemSalesUnitOfMeasure(position: ICartPositionShopSimple): UnitOfMeasure | null
		{
			return position.frameAssembly !== null ? UnitOfMeasure.unitPiece : position.item.salesUnitOfMeasure;
		}

		private removePosition(position: ICartPositionShopSimple): void {
			this.$store.dispatch('cart/removePosition', position.id).catch(() => Snackbar.deleteError());
		}

		private itemTitle(item: IItemShopSimple): string | TranslateResult | null {
			return TranslatedValueHelper.get(item.name, this.$i18n.locale);
		}

		private showCheckout(): void {
			if (this.$route.name === Routes.checkout || this.cart === null) return;
			this.$router.push(CartHelper.checkoutRoute(this.cart.id));
		}

		private showItem(position: ICartPositionShopSimple): void {
			if (this.$router.resolve(ItemHelper.detailRoute(position.item.itemNumber)).route.path === this.$route.path) return;
			this.$router.push(ItemHelper.detailRoute(position.item.itemNumber));
		}

		private finalAmount(price: IPrice): number {
			return price.amount + (this.amountsInclVat ? TotalHelper.vatAmount(price) : 0);
		}

		private reloadCart(): void {
			this.$store.dispatch('cart/loadCart', this.cart?.id);
		}

	}
</script>

<style lang="scss" scoped>
	@import '../../../scss/global';

	.ellipsis {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.cart-items {
		@include asiScrollbar();

		.item-pos:hover {
			color: var(--v-primary-base);
			cursor: pointer;
		}
	}
</style>
