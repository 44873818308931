<template>
	<div>
		<v-btn-toggle v-if="!small" v-model="frameType" color="primary" mandatory>
			<v-btn v-for="type in frameTypes" :key="type.value" :value="type.value">
				{{ type.text }}
			</v-btn>
		</v-btn-toggle>
		<AsiSelectSimple v-else v-model="frameType" :items="frameTypes" dense />
	</div>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from "vue-property-decorator";
	import {FrameAssemblyType} from "@/helpers/constants";
	import AsiSelectSimple from "@/components/common/AsiSelectSimple";
	import EnumHelper from "@/helpers/EnumHelper";

	@Component({
		components: {AsiSelectSimple}
	})
	export default class ItemCardFrameAssemblyTypeSelection extends Vue {

		@Prop({type: Boolean, default: false})
		public small!: boolean;
		@Prop({type: Number, default: FrameAssemblyType.frame})
		public frameType!: FrameAssemblyType;

		private FrameAssemblyType = FrameAssemblyType

		private get frameTypes(): { text: string; value: number | string }[] {
			return EnumHelper.toSelectItems(FrameAssemblyType, true);
		}

		@Watch('frameType')
		private onFrameTypeChanged(): void {
		    this.typeChanged();
		}

		@Emit('changed')
		public typeChanged(): FrameAssemblyType {
		    return this.frameType;
		}
	}
</script>

<style lang="scss" scoped>

</style>
